import preventDefault from '@grrr/utils/functions/preventDefault';
import { isVisible, slideDown, slideUp } from 'slide-anim';
import { formatValue } from './format-value';
import { currentLocale } from './i18n';

const printFormattedValues = function (el, value) {
    const valueElements = el.querySelectorAll('.subscribe-as-guest__value');
    Array.from(valueElements, function (item) {
        item.innerHTML = value;
    });
};

const getAndPrintCurrentValue = function (el, selector) {
    const nominalValue =
        parseInt(el.getAttribute('data-nominal-value'), 10) / 100;
    const maxBonds = parseInt(el.getAttribute('data-max-bonds'), 10);
    const bondsExceededWarning = el.querySelector(
        '.subscribe-as-guest__max-bonds-exceeded'
    );

    if (bondsExceededWarning) {
        if (selector.value > maxBonds) {
            slideDown(bondsExceededWarning);
        } else {
            slideUp(bondsExceededWarning);
        }
    }

    printFormattedValues(
        el,
        formatValue(
            Math.min(selector.value, maxBonds) * nominalValue,
            currentLocale()
        ) + (selector.value > maxBonds ? '+' : '')
    );
};

export const showElementHandler = function (el, event) {
    preventDefault(event);
    const showElement = document.querySelector(
        el.getAttribute('data-show-element-selector')
    );
    if (isVisible(showElement)) {
        slideUp(showElement);
    } else {
        slideDown(showElement);
    }
};

export const totalBondValueEnhancer = function (el) {
    const selector = el.querySelector('#quantity');
    selector.addEventListener('change', function () {
        getAndPrintCurrentValue(el, selector);
    });

    setTimeout(function () {
        getAndPrintCurrentValue(el, selector);
    }, 0);
};
