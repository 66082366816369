import { slideUp } from 'slide-anim';

export const cleanWarningsAfterNewInputEnhancer = function (el) {
    const invalidFields = el.querySelectorAll('.form__input--invalid');
    Array.from(invalidFields, function (input) {
        input.addEventListener('change', function () {
            if (this.value.length > 0) {
                this.classList.remove('form__input--invalid');
                slideUp(this.nextElementSibling);
            }
        });
    });
};
