import { slideDown, slideUp } from 'slide-anim';
import { observe, PAYMENT_METHOD_WAS_CHANGED } from './observer';

export const paymentIssuersEnhancer = function (el) {
    observe(PAYMENT_METHOD_WAS_CHANGED, (paymentMethod) => {
        if (paymentMethod === 'ideal') {
            slideDown(el);
        } else {
            slideUp(el);
        }
    });
};
