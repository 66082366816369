import { slideDown, slideUp } from 'slide-anim';
import { observe, PAYMENT_METHOD_WAS_CHANGED } from './observer';

export const showBancontactWarning = (el) => {
    el.style.display = 'none';

    observe(PAYMENT_METHOD_WAS_CHANGED, function (paymentmethod) {
        if (paymentmethod === 'bancontact') {
            slideDown(el);
        } else {
            slideUp(el);
        }
    });
};
