import 'array-from-polyfill';
import 'fetch-polyfill';

import { onDomReady } from '@grrr/ready';
import { enhance, handle } from '@grrr/hansel';
import { accountTypeInputEnhancer } from './modules/account-type-input-enhancer';
import { autoSelectCountryEnhancer } from './modules/auto-select-country';
import { autoTargetBlankEnhancer } from './modules/auto-target-blank';
import { businessNameEnhancer } from './modules/business-name-enhancer';
import { cleanWarningsAfterNewInputEnhancer } from './modules/form-helpers';
import { cocNumberEnhancer } from './modules/coc-number-enhancer';
import { paymentMethodInputEnhancer } from './modules/payment-method-input-enhancer';
import { paymentIssuersEnhancer } from './modules/payment-issuers-enhancer';
import { showBancontactWarning } from './modules/show-bancontact-warning';
import { slideNextHandler } from './modules/slide';
import {
    showElementHandler,
    totalBondValueEnhancer,
} from './modules/subscribe-as-guest';
import {
    calculateTotalEnhancer,
    numberFieldManipulatorHandler,
    showGetNewCodeEnhancer,
} from './modules/subscribe-by-extending';
import { default as subMenu } from './modules/sub-menu';
import '../styles/base.scss';
import SentryInitialization from '../../modules/SentryInitialization.js';

const addClassOnLoad = function (el) {
    const className = el.getAttribute('data-class');
    el.classList.add(`js-${className}`);
};

/**
 * Import all images and fonts to make Vite aware of your assets by importing the static assets.
 */
import.meta.glob(['../images/**', '../fonts/**']);

if (import.meta.env.VITE_SENTRY_DSN) {
    SentryInitialization();
}

onDomReady(() => {
    subMenu.init();
    enhance(document.documentElement, {
        accountTypeInputEnhancer,
        addClassOnLoad,
        autoSelectCountryEnhancer,
        autoTargetBlankEnhancer,
        businessNameEnhancer,
        calculateTotalEnhancer,
        cleanWarningsAfterNewInputEnhancer,
        cocNumberEnhancer,
        paymentIssuersEnhancer,
        paymentMethodInputEnhancer,
        showBancontactWarning,
        showGetNewCodeEnhancer,
        totalBondValueEnhancer,
    });

    handle(document.documentElement, {
        numberFieldManipulatorHandler,
        showElementHandler,
        slideNextHandler,
    });
});
