import { slideDown, slideUp } from 'slide-anim';
import { ACCOUNT_TYPE_WAS_CHANGED, observe } from './observer';

const toggle = (accountType, el) => {
    if (accountType === 'business') {
        slideDown(el);
    } else {
        slideUp(el);
    }
};

export const businessNameEnhancer = function (el) {
    toggle(el.getAttribute('data-business-name-enhancer-account_type'), el);

    observe(ACCOUNT_TYPE_WAS_CHANGED, (accountType) => {
        toggle(accountType, el);
    });
};
